
import { defineComponent } from 'vue';
import { orderBy } from 'lodash';

import UpButton from '@/components/ui/UpButton.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import InputSearch from '@/components/ui/InputSearch.vue';
import SortOrderProgramsMenu from '@/components/catalogue/SortOrderProgramsMenu.vue';
import LearningPrograms from '@/components/catalogue/LearningPrograms.vue';
import InfinityScroll from '@/components/ui/InfinityScroll.vue';
import CatalogueSidebar from '@/components/catalogue/CatalogueSidebar/CatalogueSidebar.vue';
import EmptyAtomsFilteredList from '@/components/catalogue/EmptyAtomsFilteredList.vue';
import RecommendedPrograms from '@/components/catalogue/RecommendedPrograms';

import * as CatalogueService from '@/services/api/catalogue';
import * as LxpAssignmentService from '@/services/api/lxp-assignment';

import { IBreadcrumb, UUID } from '@/domains/common';
import { IAssignment } from '@/domains/assignment';
import { IAtomWithAssigments } from '@/domains/track';
import {
  AtomSort, AtomStatus, AtomType, IAtom,
} from '@/domains/atom';
import { Category } from '@/domains/category';
import { IContentProvider, MY_COMPANY_PROVIDER } from '@/domains/content-provider';
import { formatAtomsByMyCompanyProvider, formatRequestProvidersByMyCompany } from '@/utils/atom';

class DefaultPagination {
  page = 1;

  limit = 18;

  total = 0;
}

export default defineComponent({
  name: 'LearningCatalogueLegacy',
  components: {
    UpButton,
    TTBackButton,
    InputSearch,
    SortOrderProgramsMenu,
    LearningPrograms,
    InfinityScroll,
    CatalogueSidebar,
    EmptyAtomsFilteredList,
    RecommendedPrograms,
  },
  inject: ['Names'],
  setup() {},
  data() {
    return {
      atom: {
        items: [] as IAtomWithAssigments[],
        pagination: new DefaultPagination(),
        isLoading: false,
        wereLoadedFirstItems: false,
      },
      filters: {
        atomName: [] as AtomType[],
        search: '',
        sort: AtomSort.UPDATED_AT_DESC,
        providers: [] as UUID[],
        categories: [] as UUID[],
        durations: [] as [Number | null, Number | null][],
      },
      contentProviders: [] as IContentProvider[],
      categoriesList: [] as Category[],
      assignments: [] as IAssignment[],
      recommendations: {
        items: [] as IAtom[],
        pagination: new DefaultPagination(),
        isLoading: true,
      },
    };
  },
  computed: {
    breadcrumbs(): IBreadcrumb[] {
      return [
        {
          text: this.$t('LearningCatalogue.breadcrumbs.programs'),
          to: {
            name: this.Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-program-link',
        },
        {
          text: this.$t('LearningCatalogue.breadcrumbs.allPrograms'),
          disabled: true,
          'data-test-label': 'catalogue-link',
        },
      ];
    },

    formatedCategoriesIds(): UUID[] {
      return this.filters.categories || [];
    },
    formatedProvidersIds(): UUID[] {
      return this.filters.providers || [];
    },

    formatedDuration(): Array<Number | null> {
      let durations: Array<Number | null> = [];
      this.filters.durations.forEach((duration) => {
        durations = durations.concat(duration);
      });

      durations = orderBy(durations);
      return durations.length ? [durations[0], durations[durations.length - 1]] : [];
    },

    showAtoms(): boolean {
      return !!this.atom.items.length;
    },

    showEmptyAtomsList(): boolean {
      return !this.atom.items.length && this.atom.wereLoadedFirstItems;
    },

    atomsGettingParams(): any {
      return {
        page: this.atom.pagination.page,
        limit: this.atom.pagination.limit,
        statuses: [AtomStatus.PUBLISHED],
        search: this.filters.search,
        sort: this.filters.sort,
        providers: formatRequestProvidersByMyCompany(this.filters?.providers || []),
        categories: this.formatedCategoriesIds,
        duration: this.formatedDuration,
        atomNames: [AtomType.TRACK],
      };
    },

    maxPageNumberRecommendedAtoms(): number {
      const { total, limit } = this.recommendations.pagination;

      return Math.ceil(total / limit);
    },

    hasRecommendations(): boolean {
      return this.recommendations.items.length > 0;
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        this.atom.pagination = new DefaultPagination();
        await this.fetchAtoms();
      },
    },
    'atom.pagination.page': {
      async handler(newPage, oldPage) {
        if (newPage !== oldPage) {
          await this.addNextPaginationAtoms();
        }
      },
    },
    'recommendations.pagination.page': {
      async handler(newPage, oldPage) {
        if (newPage !== oldPage) {
          await this.addNextPaginationRecommendedAtoms();
        }
      },
    },
  },

  async created() {
    this.fetchProviders();
    this.fetchCategories();
    this.fetchAtoms();
    this.getRecommendedAtoms();

    this.atom.wereLoadedFirstItems = true;
  },

  methods: {
    next() {
      if (this.recommendations.pagination.page < this.maxPageNumberRecommendedAtoms) {
        this.recommendations.pagination.page += 1;
      }
    },
    async getRecommendedAtoms() {
      try {
        this.recommendations.isLoading = true;

        const { atoms, pagination } = await CatalogueService.recommendedTracksGet({
          params: {
            page: this.recommendations.pagination.page,
            limit: this.recommendations.pagination.limit,
          },
        });

        this.recommendations.items = formatAtomsByMyCompanyProvider(atoms);

        this.recommendations.pagination.page = pagination.page;
        this.recommendations.pagination.limit = pagination.limit;
        this.recommendations.pagination.total = pagination.total;
      } catch (e: any) {
        console.error(e);
        this.$di.notify.error({
          content: this.$t('LearningCatalogue.errors.cantLoadRecommendations'),
        });
      } finally {
        this.recommendations.isLoading = false;
      }
    },
    async addNextPaginationRecommendedAtoms() {
      try {
        const { atoms, pagination } = await CatalogueService.recommendedTracksGet({
          params: {
            page: this.recommendations.pagination.page,
            limit: this.recommendations.pagination.limit,
          },
        });

        this.recommendations.items.push(...formatAtomsByMyCompanyProvider(atoms));

        this.recommendations.pagination.page = pagination.page;
        this.recommendations.pagination.limit = pagination.limit;
        this.recommendations.pagination.total = pagination.total;
      } catch (e: any) {
        console.error(e);
        this.$di.notify.error({
          content: this.$t('LearningCatalogue.errors.cantLoadRecommendations'),
        });
      }
    },
    async changeFilters({
      providers,
      categories,
      durations,
    }: {
      providers: UUID[];
      categories: UUID[];
      durations: [Number | null, Number | null][];
    }) {
      this.filters.categories = categories;
      this.filters.providers = providers;
      this.filters.durations = durations;
    },

    async fetchAtoms() {
      try {
        this.atom.isLoading = true;

        const { atoms, pagination } = await CatalogueService.userTracksGet({
          params: this.atomsGettingParams,
        });

        this.atom.items = formatAtomsByMyCompanyProvider(atoms);

        this.atom.pagination.page = pagination.page;
        this.atom.pagination.limit = pagination.limit;
        this.atom.pagination.total = pagination.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.atom.isLoading = false;
      }
    },

    async addNextPaginationAtoms() {
      try {
        this.atom.isLoading = true;

        const { atoms, pagination } = await CatalogueService.userTracksGet({
          params: this.atomsGettingParams,
        });

        this.atom.items.push(...formatAtomsByMyCompanyProvider(atoms));

        this.atom.pagination.page = pagination.page;
        this.atom.pagination.limit = pagination.limit;
        this.atom.pagination.total = pagination.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.atom.isLoading = false;
      }
    },

    async loadNextPage() {
      this.atom.pagination.page += 1;
    },

    async fetchProviders() {
      try {
        const { providers } = await CatalogueService.contentProvidersGet();
        this.contentProviders = [MY_COMPANY_PROVIDER, ...providers];
      } catch (e) {
        console.error(e);
      }
    },

    async fetchCategories() {
      try {
        const { categories } = await CatalogueService.atomCategoriesGet();
        this.categoriesList = categories;
      } catch (e) {
        console.error(e);
      }
    },

    async assignAtom({ id: catalogueAtomId, _, isRecommendations }: { id: UUID; _: any; isRecommendations: boolean }) {
      try {
        const { assignmentUserId, selfAssigned } = await LxpAssignmentService.userTrackAssign({
          catalogueAtomId,
          data: {
            onRecommendation: isRecommendations,
          },
        });

        if (isRecommendations) {
          await this.getRecommendedAtoms();
        }

        const atom = this.atom.items.find((item) => item.id === catalogueAtomId);
        atom?.assignments?.push({
          id: 0, // TODO: Разобраться зачем нужен этот параметр
          assignmentUserId,
          selfAssigned,
        });

        this.$di.notify.success({
          content: this.$t('LearningCatalogue.assignSuccessMessage'),
        });
      } catch (e: any) {
        console.error(e);
        this.$di.notify.error({
          content: e.response.data.errors?.map((error: { message: string }) => error.message).join(', '),
        });
      }
    },

    async onTrackClickHandler({ id, isRecommendations = false }: { id: UUID; isRecommendations: boolean }) {
      let atom;

      if (isRecommendations) {
        atom = this.recommendations.items.find((item) => item.id === id);
      } else {
        atom = this.atom.items.find((item) => item.id === id);

        if (atom && atom.assignments && atom.assignments.length > 0) {
          const { assignmentUserId } = atom.assignments[0];

          try {
            const { playerSessionId } = await LxpAssignmentService.userAssignmentRun({
              assignmentUserId,
              data: {
                catalogueAtomMainId: id,
              },
            });

            if (assignmentUserId && playerSessionId) {
              return this.$router.push({
                name: this.Names.R_APP_LEARNING_TRACK,
                params: {
                  trackSessionId: String(playerSessionId),
                },
              });
            }
          } catch (e: any) {
            console.error(e);
            this.$di.notify.error({
              content: e.response.data.errors?.map((error: { message: string }) => error.message).join(', '),
            });
          }
        }
      }

      return this.$router.push({
        name: this.Names.R_APP_LEARNING_ATOM_PREVIEW,
        params: {
          catalogueAtomId: id,
        },
      });
    },
  },
});
