
import Vue, { PropType } from 'vue';
import CatalogueLearningProgram from '@/components/tracks/CatalogueLearningPrograms/CatalogueLearningProgram.vue';
import { IAtomWithAssigments } from '@/domains/track';

export default Vue.extend({
  name: 'LearningPrograms',
  components: {
    CatalogueLearningProgram,
  },

  props: {
    items: {
      type: Array as PropType<IAtomWithAssigments[]>,
      default: () => [],
    },
  },
  methods: {
    isAssign(item: IAtomWithAssigments): boolean {
      return !!item.assignments?.length;
    },
  },
});
