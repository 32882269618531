
import Vue, { PropType } from 'vue';

import BaseCarousel from '@/components/ui/BaseCarousel/BaseCarousel.vue';
import CatalogueLearningProgram from '@/components/tracks/CatalogueLearningPrograms/CatalogueLearningProgram.vue';
import Loader from '@/components/ui/Loader.vue';

import { IAtom } from '@/domains/atom';
import { UUID } from '@/domains/common';

export default Vue.extend({
  name: 'RecommendedPrograms',
  components: {
    BaseCarousel,
    CatalogueLearningProgram,
    Loader,
  },
  props: {
    items: {
      type: Array as PropType<IAtom[]>,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    next() {
      this.$emit('click:next');
    },
    assign({ id, isAssign }: { id: UUID, isAssign: boolean }) {
      this.$emit('click:assign', { id, isAssign, isRecommendations: true });
    },
    goToTrack({ id }: { id: UUID }) {
      this.$emit('click:go-to-track', { id, isRecommendations: true });
    },
  },
});
